import { Theme, ThemeOptions, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles';

// https://mui.com/customization/theme-components/#default-props
export const defaultProps: ComponentsProps['Header'] = {};

// https://mui.com/customization/theme-components/#global-style-overrides
export const styleOverrides: ComponentsOverrides<Theme>['Header'] = {
  // Set some static styles
  contentContainer: ({ theme }) => ({
    height: '100px',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10)
    }
  }),
  root: {
    'backgroundColor': 'transparent!important',
    '& [class*="Header-logo"]': {
      transition: '.2s'
    },
    'transition': '.2s',

    ':not(.MuiPaper-elevation4):not(:hover)': {
      'backgroundColor': 'transparent!important',
      '&::before': {
        backgroundColor: 'transparent!important'
      }
    },
    '.with-hero &': {
      '@media (min-width: 640px)': {
        ':not(.MuiPaper-elevation4)': {
          'paddingTop': 8,
          'paddingBottom': 8,
          '& [class*="Header-logo"]': {
            padding: 0
          }
        }
      }
    }
  }
};

// https://mui.com/customization/theme-components/#adding-new-component-variants
const createVariants = (_theme: Theme): ComponentsVariants['Header'] => [
  // Use prop matching to set variant styles
  // {
  //   props: {
  //     variant: 'example'
  //   },
  //   style: {
  //     backgroundColor: theme.palette.primary.main
  //   }
  // }
  // Other props are also valid
  // {
  //   props: {
  //     backgroundColor: 'primary.main',
  //   },
  //   style: {
  //     color: theme.palette.primary.contrastText
  //   }
  // }
];

export default (theme: Theme): ThemeOptions => ({
  components: {
    Header: {
      // @ts-expect-error
      height: 100,
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});
