/* eslint-disable @next/next/inline-script-id */
/* eslint-disable @next/next/no-script-component-in-head */
import React from 'react';
import Head from 'next/head';
import Script from 'next/script';
import type { AppProps } from 'next/app';
import { ThemeProvider } from '@mui/system';
import '@last-rev/component-library/dist/styles.css';
import CssBaseline from '@mui/material/CssBaseline';
import theme from '@adid-festival-lr/components/src/theme';
import { createEmotionCache } from '../src/createEmotionCache';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { LazyMotion } from 'framer-motion';
import '../styles/globals.css';
// LastRev components
import dynamic from 'next/dynamic';
const SEO = dynamic(() => import('@last-rev/component-library/dist/components/SEO'));
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}
const loadFeatures = () => import('../src/features').then((res) => res.default);

function MyApp({ Component, emotionCache = clientSideEmotionCache, pageProps }: MyAppProps) {
  return (
    <CacheProvider value={emotionCache}>
      {pageProps.pageData?.page?.seo ? <SEO seo={pageProps.pageData.page.seo} /> : null}
      <Head>
        {!!pageProps.pageData?.page?.seo?.title ? <title>{pageProps.pageData.page.seo.title.value}</title> : null}
        <meta name="contentful_space" content={process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID} />
        <meta name="contentful_environment" content={process.env.NEXT_PUBLIC_CONTENTFUL_ENV} />
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.12.0/css/all.css" />
        <Script src="https://www.googletagmanager.com/gtag/js?id=G-6YLB2FFM7Z" />
        <Script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-6YLB2FFM7Z');
            `
          }}
        />
      </Head>
      <ThemeProvider theme={theme}>
        <LazyMotion features={loadFeatures}>
          <CssBaseline />
          <Component {...pageProps} />
        </LazyMotion>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default MyApp;
