import { Theme } from '@mui/material/styles';

export const largeCollectionCarousel = (theme: Theme) => ({
  props: {},
  style: {
    'minHeight': 400,
    'height': '100%',
    'background': theme.palette.secondary.main,
    '& .MuiPaper-root': {
      'backgroundColor': 'transparent',
      '& .MuiTypography-root': { color: 'white' }
    },
    [theme.breakpoints.down('md')]: {
      minHeight: '100%'
    },
    '& [class*="CollectionCarousel-carouselContainer"]': {
      paddingBottom: theme.spacing(10),
      [theme.breakpoints.down('md')]: {
        paddingBottom: theme.spacing(5)
      }
    },
    '& [class*="CollectionCarousel-carouselItem"]': {
      '& > *': {
        height: '100%',
        width: '100%',
        padding: theme.spacing(2, 8, 2, 8),
        [theme.breakpoints.down('md')]: {
          padding: theme.spacing(1),
          width: '100%'
        }
      },
      '& .MuiCard-root': {
        padding: theme.spacing(0, 10, 0, 10),
        [theme.breakpoints.down('md')]: {
          padding: theme.spacing(2)
        }
      },
      '& .MuiCardMedia-root': {
        display: 'flex',
        alignItems: 'center',
        maxHeight: 350,
        width: '100%',
        height: '98%',
        padding: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
          width: '100%',
          height: '70%'
        }
      },
      '& iframe': {
        maxHeight: 250,
        maxWidth: 450,
        [theme.breakpoints.down('md')]: {
          maxHeight: '100%',
          maxWidth: '100%'
        }
      },
      '& .MuiCardContent-root': {
        padding: theme.spacing(1),
        justifyContent: 'center'
      },
      '& .MuiTypography-root': {
        textAlign: 'left',
        [theme.breakpoints.down('md')]: {
          textAlign: 'center'
        }
      }
    }
  }
});

export const smallCollectionCarousel = (theme: Theme) => ({
  props: {
    variant: 'carousel-small'
  },
  style: {
    'maxHeight': 350,
    '& .swiper-slide': {
      width: '100%'
    },
    '& .swiper-container': {
      paddingBottom: theme.spacing(6)
    },
    '& .swiper-button-prev , .swiper-button-next': {
      marginTop: theme.spacing(-5),
      color: theme.palette.primary.main
    },
    '& [class*="CollectionCarousel-carouselItem"]': {
      '& > *': {
        height: '100%',
        width: '100%'
      },
      '& .MuiCardContent-root': {
        height: '100%'
      },
      '& img': {
        objectPosition: 'top',
        objectFit: 'cover'
      },
      '&:hover': {
        '& .MuiCardContent-root': {
          borderStyle: 'solid',
          borderColor: theme.palette.primary.main,
          width: '98%',
          height: '98%'
        },
        '& .MuiPaper-root': {
          'boxShadow': 'none',
          '& img': {
            opacity: 0.4
          }
        }
      }
    }
  }
});

const variants = [largeCollectionCarousel, smallCollectionCarousel];

const createSectionVariants = (theme: Theme) => {
  return variants.map((creator) => creator(theme));
};

export default createSectionVariants;
