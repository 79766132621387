import { Theme } from '@mui/material/styles';

// Here's how you define different variants of a component.
export const defaultHeroVariant = (theme: Theme) => ({
  props: {
    variant: 'default'
  },
  style: {
    '&': {
      minHeight: '700px',
      height: '70vh',
      paddingTop: 64 + 40
    },
    '& h1': {
      fontSize: '40pt',
      fontFamily: 'Futura Condensed Bold',
      wordSpacing: '6px'
    },
    '& h2': {
      fontSize: '18pt',
      fontFamily: 'Futura'
    },
    [theme.breakpoints.down('md')]: {
      '&': {
        height: 'calc(100vh - 64px)'
      },
      '& h1': {
        fontSize: '60px',
        fontFamily: 'Futura Condensed Bold'
      },
      '& h2': {
        fontSize: '26px',
        fontFamily: 'Futura'
      }
    }
  }
});

const variants = [defaultHeroVariant];

const createCardVariants = (theme: Theme) => {
  return variants.map((creator) => creator(theme));
};

export default createCardVariants;
