// @ts-nocheck
import createTheme from '@last-rev/component-library/dist/theme/createTheme';
import { responsiveFontSizes } from '@mui/material/styles';

import { red } from '@mui/material/colors';
import { PaletteColor, PaletteOptions } from '@mui/material/styles';
import createCardVariants from './createCardVariants';
import createCollectionCarouselVariants from './createCollectionCarouselVariants';
import createHeroVariants from './createHeroVariants';
import createTextVariants from './createTextVariants';
import Section from '../components/Section/Section.theme';
import Hero from '../components/Hero/Hero.theme';
import Header from '../components/Header/Header.theme';
import Card from '../components/Card/Card.theme';
import NavigationItem from '../components/NavigationItem/NavigationItem.theme';
import merge from 'deepmerge';
declare module '@last-rev/component-library/dist/theme/createTheme' {
  interface CustomPalette {
    'custom-color': PaletteOptions['primary'];
  }

  interface CustomPaletteOptions {
    'custom-color': PaletteOptions['primary'];
  }
}

export const baseTheme: any = createTheme({
  typography: {
    fontFamily: 'Futura',
    body1: {
      fontFamily: 'Futura',
      fontSize: '12pt',
      fontWeight: 'normal'
    },
    h1: {
      textTransform: 'uppercase',
      fontWeight: 800,
      fontSize: '3rem'
    },
    h3: {
      textTransform: 'uppercase',
      fontWeight: 300,
      fontSize: '2rem',
      paddingBottom: 0
      // color: `${baseTheme.palette.primary.main}`'
    },
    h4: {
      textTransform: 'uppercase',
      fontWeight: 300,
      fontSize: '2rem'
      // fontFamily: 'Violation'
      // color: `${baseTheme.palette.primary.main}`'
    },
    h6: {
      fontFamily: 'Futura',
      fontWeight: 300
    },
    h2: {
      fontFamily: 'Open Sans',
      fontWeight: 800
    }
  },
  palette: {
    // mode: 'dark',
    // black: '#666666',
    primary: {
      main: '#119dfc',
      light: '#55c5fd',
      contrastText: 'white'
    },
    // primary: {
    // lightBlue: {
    //   main: '#b6d6e7',
    //   contrastText: 'white'
    // },
    //   main: '#FFF',
    //   contrastText: 'black '
    // },
    secondary: {
      main: '#FFF',
      contrastText: '#119dfc'
    },
    // error: {
    //   main: red.A400
    // },
    background: {
      default: '#119dfc',
      paper: '#119dfc'
    },
    text: {
      primary: '#FFF'
    }
  }
});

const theme = createTheme(
  merge.all([
    Section(baseTheme),
    Hero(baseTheme),
    Header(baseTheme),
    Card(baseTheme),
    NavigationItem(baseTheme),
    {
      components: {
        Header: {
          height: 64,
          mobileMenuBreakpoint: 'lg',
          styleOverrides: {
            root: {
              'transition': 'all 0.3s ease-in-out',
              // 'backgroundColor': baseTheme.palette.primary.main,
              '&::before': {
                transition: 'all 0.3s ease-in-out'
                // backgroundColor: baseTheme.palette.primary.main
              },
              '.MuiLink-root': {
                color: 'white',
                fontSize: '11pt',
                fontFamily: 'Futura Bold'
              },
              'textTransform': 'uppercase',
              '.MuiIconButton-root': {
                background: 'transparent'
              }
            },
            logo: {
              padding: baseTheme.spacing(1, 0)
            },
            contentContainer: {
              'height': 65,
              '> .MuiLink-root': {
                padding: '1px 0'
              },
              'justifyContent': 'center',
              // Styles to re-order the header in Desktop
              [baseTheme.breakpoints.up('lg')]: {
                //Spacers
                '> :nth-child(2), > :nth-child(4)': {
                  display: 'none'
                },
                //Logo
                '> :nth-child(1)': {
                  order: 2,
                  marginLeft: baseTheme.spacing(3),
                  marginRight: baseTheme.spacing(3)
                },
                // First Navigation bar
                '> :nth-child(3)': {
                  'order': 1,
                  '> div': {
                    '> :nth-child(4)': { display: 'none' },
                    '> :nth-child(5)': { display: 'none' },
                    '> :nth-child(6)': { display: 'none' }
                  }
                },
                // Second Navigation bar
                '> :nth-child(5)': {
                  order: 3
                }
              },
              [baseTheme.breakpoints.down('md')]: {
                'height': 64,
                // Second Navigation bar
                '> :nth-child(5)': {
                  display: 'none'
                }
              }
            }
          }
        },
        Hero: {
          variants: createHeroVariants(baseTheme),
          styleOverrides: {
            root: {
              // Anti-Flicker
              'transition': 'opacity .3s, background-color .3s ease-in-out',
              'transition-delay': '.3s',
              'div, [class*="Text-root"] > div': {
                'transition': 'opacity .3s, background-color .3s ease-in-out',
                'transition-delay': '.2s'
              },
              '.async-hide &': {
                'backgroundColor': 'rgba(0,0,0,0)',
                '[class*="Hero-root"]': {
                  'transition': '0s',
                  'transition-delay': '0s',
                  'opacity': '0 !important'
                },
                'div, [class*="Text-root"] > *': {
                  'transition': '0s',
                  'transition-delay': '0s',
                  'opacity': '0 !important'
                }
              },
              '[class*="Text-root"]': {
                '.MuiTypography-h3': {
                  // paddingBottom: baseTheme.spacing(3)
                  color: baseTheme.palette.secondary.main
                },
                '.MuiTypography-h4': {
                  color: baseTheme.palette.secondary.main
                  // color: baseTheme.palette.primary.main
                },
                '> img': {
                  maxWidth: 238,
                  margin: 'auto',
                  position: 'absolute',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  bottom: baseTheme.spacing(4)
                },
                ...[...Array(10).keys()].reduce(
                  (accum, i) => ({
                    ...accum,
                    [`> ::nth-child(${i})`]: {
                      transitionDelay: `${i * 0.25}s`
                    }
                  }),
                  {}
                )
              },
              'h3.MuiTypography-h3': {
                fontFamily: 'Violation',
                fontWeight: 300,
                paddingBottom: 0,
                marginBottom: -10
              }
            },
            backgroundRoot: {
              '&:after': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%'
                // boxShadow: 'inset 0 -90px 110px -10px rgb(0 0 0)'
                // boxShadow: 'inset 0 -90px 110px -10px rgba(255,255,255, 0.3)'
              }
            },
            contentHeightXL: {
              minHeight: 'auto',
              height: 'calc(100vh - 64px)'
            },
            contentContainer: {
              'alignItems': 'center',
              '> div': {
                'justifyContent': 'center',
                'textAlign': 'center',
                '> div': {
                  maxWidth: '100%',
                  flexBasis: '100%'
                }
              }
            }
          }
        },
        NavigationBar: {
          styleOverrides: {
            root: {
              '& .MuiLink-root': {
                'textDecoration': 'none',
                '&.MuiLink-selected': { fontWeight: 'bold' }
              },
              '& [class*="NavigationItem-menuRoot"]': {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                background: baseTheme.palette.primary.main
              }
            }
          }
        },
        Text: {
          variants: createTextVariants(baseTheme),
          styleOverrides: {
            root: {
              // Removes extra p tag being added by contentful
              '& > br:last-child': {
                display: 'none'
              },
              '.MuiTypography-body1': {
                paddingBottom: baseTheme.spacing(2)
              },
              '.MuiTypography-h2': {
                paddingBottom: baseTheme.spacing(3)
                // color: baseTheme.palette.primary.main
              },
              '.MuiTypography-h3': {
                // paddingBottom: baseTheme.spacing(3)
                // color: baseTheme.palette.primary.main
              },
              '.MuiTypography-h4': {
                paddingBottom: baseTheme.spacing(3)
                // color: baseTheme.palette.primary.main
              },
              'li .MuiTypography-body1': {
                paddingBottom: baseTheme.spacing(1),
                fontSize: '1rem'
              }
            }
          }
        },
        Media: {
          styleOverrides: {
            root: {
              width: '100%',
              display: 'block'
            }
          }
        },
        Card: {
          variants: createCardVariants(baseTheme)
        },
        Collection: {
          styleOverrides: {
            introText: {
              // Given a Section with more than one Collection
              // Add paddingTop for introText as long as it's not the first one
              '[class*="Section-gridItem"]:not(:first-child) > [class*="Collection-root"] > &': {
                paddingTop: baseTheme.spacing(4)
              }
            }
          }
        },
        CollectionFiltered: {},
        CollectionCarousel: {
          variants: createCollectionCarouselVariants(baseTheme)
        },
        MuiAccordion: {
          styleOverrides: {
            root: {
              backgroundColor: `${baseTheme.palette.primary.main}`
            }
          }
        },
        CollectionAccordion: {
          styleOverrides: {
            root: {
              padding: baseTheme.spacing(10, 0)
            },
            accordionItem: {
              'paddingBottom': baseTheme.spacing(4),
              // 'backgroundColor': 'white',
              '.MuiTypography-root': {
                color: 'white',
                fontSize: '20px'
              },
              '.MuiTypography-body1': {
                fontSize: '16px'
              }
            }
          }
        },

        Section: {
          variants: [
            {
              props: { variant: 'four-per-row' },
              style: {
                '& [class*="Section-gridItem"]': {
                  flexBasis: '25%',
                  maxWidth: '25%',
                  [baseTheme.breakpoints.down('md')]: {
                    flexBasis: '50%',
                    maxWidth: '50%'
                  }
                }
              }
            },
            {
              props: { variant: 'one-per-row' },
              style: {
                '& [class*="Section-gridItem"]': {
                  display: 'flex',
                  justifyContent: 'center'
                }
              }
            }
          ],
          styleOverrides: {
            root: {
              'main > &': {
                background: `${baseTheme.palette.primary.main}`,
                background: `linear-gradient(180deg, ${baseTheme.palette.primary.main} 0%, ${baseTheme.palette.primary.light})`,
                color: 'white'
              }
            },
            gridContainer: {
              justifyContent: 'center'
            },
            gridItem: {
              display: 'flex',
              justifyContent: 'center'
            }
          }
        },
        Footer: {
          styleOverrides: {
            root: {
              'padding': baseTheme.spacing(6, 0),
              'background': `${baseTheme.palette.primary.main}`,
              'background': `linear-gradient(180deg, ${baseTheme.palette.primary.main} 0%, ${baseTheme.palette.primary.light})`,
              'color': 'white',
              '& [class*="Section-gridContainer"]': {
                justifyContent: 'center'
              },
              '& [class*="Section-gridItem"]': {
                flexGrow: 0
              },
              '& .MuiIconButton-root': {
                color: 'white',
                backgroundColor: 'rgba(255,255,255,0.2)'
              }
            }
          }
        },
        MuiButton: {
          styleOverrides: {
            root: {
              color: '#666',
              fontSize: '18px',
              fontFamily: 'Roboto Bold',
              padding: baseTheme.spacing(1, 4)
            },
            containedPrimary: {
              color: 'white'
            }
          }
        },

        MuiIconButton: {
          styleOverrides: {
            root: {
              backgroundColor: 'rgba(0,0,0,0.2)'
            }
          }
        },
        MuiFilledInput: {
          styleOverrides: {
            root: {
              backgroundColor: 'rgba(255, 255, 255, 0.3)'
            }
          }
        },
        MuiTextField: {
          styleOverrides: {
            root: {
              textTransform: 'uppercase'
            },
            input: {}
          }
        },
        MuiGrid: {
          styleOverrides: {
            root: {
              [baseTheme.breakpoints.down('sm')]: {
                '&.MuiGrid-spacing-xs-10': {
                  'marginLeft': baseTheme.spacing(-2),
                  'marginTop': baseTheme.spacing(-2),
                  'width': 'calc(100% + 16px)',
                  '.MuiGrid-item': {
                    paddingLeft: baseTheme.spacing(2),
                    paddingTop: baseTheme.spacing(2)
                  }
                }
              }
            }
          }
        },

        MuiContainer: {
          defaultProps: {
            maxWidth: 'xl'
          },
          styleOverrides: {
            root: {
              [baseTheme.breakpoints.down('sm')]: {
                'paddingLeft': baseTheme.spacing(3),
                'paddingRight': baseTheme.spacing(3),
                '& .MuiContainer-disableGutters': {
                  paddingLeft: 0,
                  paddingRight: 0
                }
              },
              [baseTheme.breakpoints.up('sm')]: {
                'paddingLeft': baseTheme.spacing(10),
                'paddingRight': baseTheme.spacing(10),
                '& .MuiContainer-disableGutters': {
                  paddingLeft: 0,
                  paddingRight: 0
                }
              }
            }
          }
        }
      }
    }
  ]),
  baseTheme
);

export default responsiveFontSizes(theme);
