import { Theme } from '@mui/material/styles';

// Here's how you define different variants of a component.
export const defaultCardVariant = (theme: Theme) => ({
  props: {
    variant: 'default'
  },
  style: {
    'borderRadius': '8px',
    'display': 'grid',
    'gridTemplateColumns': '40% 60%',
    'transition': '.3s ease-in-out',
    '&:hover': {
      transform: 'translateY(-20px)'
    },
    'width': '100%',
    '& .MuiCardMedia-root': {
      'gridColumn': 1,
      'width': '100%',
      'height': '100%',
      'overflow': 'hidden',
      '& [class*="Media-root"]': {
        transform: 'scale(1)',
        aspectRatio: '1 / 1',
        objectFit: 'cover',
        width: '100%',
        height: '100%'
      }
    },
    '[class*="MuiCardContent-root"]': {
      'width': '100%',
      'display': 'flex',
      'flexDirection': 'column',
      'padding': theme.spacing(2, 4),
      '&:last-child': {
        paddingBottom: theme.spacing(2)
      }
    },
    '[class*="Typography-root"]': {
      color: 'white'
    },

    '[class*="Typography-h3"]': {
      fontFamily: 'Roboto Bold',
      fontSize: '22px',
      color: 'white'
    },
    '[class*="Typography-h4"]': {
      order: 3,
      fontSize: '22px',
      color: 'white',
      fontFamily: 'Roboto Bold',
      paddingBottom: 15
    },
    '[class*="CardActions-root"]': {
      position: 'absolute',
      right: theme.spacing(4),
      bottom: theme.spacing(3),
      padding: 0
    },
    '[class*="MuiTypography-body1"]': {
      fontSize: '11pt',
      lineHeight: '1.2',
      marginTop: '5px'
    },
    '[class*="Text-root"]': {
      flex: 1,
      marginBottom: theme.spacing(4)
    },
    '[class*="MuiButton-root"]': {
      fontSize: '14px',
      padding: '6px 14px'
    },
    '.MuiIconButton-root': {
      'backgroundColor': 'rgba(255,255,255,0.2)',
      // 'backgroundColor': 'transparent',
      '.MuiIcon-root': {
        color: 'white',
        width: '100%'
      }
    },
    [theme.breakpoints.down('sm')]: {
      'display': 'flex',
      'flexDirection': 'column',
      '& .MuiCardMedia-root': {
        'width': '100%',
        'height': 'auto',
        '& [class*="Media-root"]': {
          transform: 'scale(1)',
          aspectRatio: '1 / 1',
          objectFit: 'cover',
          width: '100%'
        }
      },
      '[class*="MuiCardContent-root"]': {
        'width': '100%',
        'display': 'flex',
        'flexDirection': 'column',
        'padding': theme.spacing(2, 4),
        '&:last-child': {
          paddingBottom: theme.spacing(2)
        }
      },
      '[class*="CardActions-root"]': {
        position: 'relative',
        right: 0,
        bottom: 0,
        padding: 0,
        order: 4
      },
      '[class*="Typography-h4"]': {
        order: 2
      },
      '[class*="Text-root"]': {
        flex: 'unset',
        marginBottom: theme.spacing(0)
      }
    }
  }
});
export const mediaCardVariant = (theme: Theme) => ({
  props: {
    variant: 'media'
  },
  style: {
    'transition': '.2s ease-in-out',
    'background': 'transparent',
    'boxShadow': 'none',
    '&:hover': {
      transform: 'translateY(-20px)'
    },
    'width': '100%',
    'height': '100%',
    '& .MuiCardMedia-root': {
      'height': '100%',
      'display': 'flex',
      'justifyContent': 'center',
      'alignItems': 'center',

      '& [class*="Media-root"]': {
        objectFit: 'contain'
      }
    }
  }
});

const imageAboveVariant = (theme: Theme) => ({
  props: {
    variant: 'image-above'
  },
  style: {
    'height': '100%',
    'display': 'flex',
    'flexDirection': 'column',
    'width': '100%',
    '& h3': {
      color: '#119dfc',
      fontSize: '18px',
      width: '100%',
      textAlign: 'center',
      fontFamily: 'Futura Bold',
      opacity: 1,
      transition: 'opacity .3s'
    },
    '.MuiCardActions-root': {
      'padding': 0,
      'max-height': '0px',
      'opacity': 0,
      'overflow': 'hidden',
      'transition': 'opacity .3s'
    },
    '.MuiIconButton-root': {
      'backgroundColor': 'transparent',
      '.MuiIcon-root': {
        width: '100%'
      }
    },
    '& .MuiCardContent-root': {
      'display': 'flex',
      'flexDirection': 'column',
      'justifyContent': 'center',
      'alignItems': 'center',
      'textAlign': 'center',
      'backgroundColor': 'white',
      'min-height': '80px',
      '&:last-child': {
        paddingBottom: theme.spacing(2)
      }
    },
    '& [class*="Media-root"]': {
      width: '100%',

      aspectRatio: '1/1',
      objectFit: 'cover'
    },
    '& .MuiCardMedia-root': {
      '& [class*="Media-root"]': {
        transform: 'scale(1)'
      },
      'flex': 1,
      'width': '100%',
      // 'aspectRatio': '1/1',
      'background-position': 'center center',
      'background-repeat': 'no-repeat',
      'overflow': 'hidden'
    },
    '&:hover': {
      '.MuiCardActions-root': {
        'max-height': '100px',
        'opacity': 1
      },

      '.MuiTypography-h3': {
        'max-height': '0px',
        'opacity': 0,
        'overflow': 'hidden'
      }
    },

    '.MuiCardContent-root.css-46bh2p-MuiCardContent-root': {}
  }
});

const imageLeftVariant = (theme: Theme) => ({
  props: {
    variant: 'image-left'
  },
  style: {
    'height': '100%',
    'display': 'flex',
    'flexDirection': 'row-reverse',
    'width': '100%',
    '& h3': {
      color: '#119dfc',
      fontSize: '16px',
      width: '100%',
      textAlign: 'left',
      fontFamily: 'Futura Bold',
      opacity: 1,
      transition: 'opacity .3s'
    },
    '.MuiCardActions-root': {
      'padding': 0,
      'max-height': '0px',
      'opacity': 0,
      'overflow': 'hidden',
      'transition': 'opacity .3s'
    },
    '.MuiIconButton-root': {
      'backgroundColor': 'transparent',
      '.MuiIcon-root': {
        width: '100%'
      }
    },
    '& .MuiCardContent-root': {
      'display': 'flex',
      'flexDirection': 'column',
      'justifyContent': 'center',
      'alignItems': 'flex-start',
      'textAlign': 'left',
      'backgroundColor': 'white',

      'width': 'calc(100% - 64px)',
      'flex': 1,
      '&:last-child': {
        paddingBottom: theme.spacing(2)
      }
    },
    '& [class*="Media-root"]': {
      width: '100%',
      height: '100%',
      aspectRatio: '1/1',
      objectFit: 'cover',
      objectPosition: 'center'
    },
    '& .MuiCardMedia-root': {
      'width': '64px',
      'minHeight': '64px',
      'height': '100%',
      '& [class*="Media-root"]': {
        transform: 'scale(1)'
      },

      // 'aspectRatio': '1/1',
      'background-position': 'center center',
      'background-repeat': 'no-repeat',
      'overflow': 'hidden'
    },
    '&:hover': {
      '.MuiCardActions-root': {
        'max-height': '100px',
        'opacity': 1
      },

      '.MuiTypography-h3': {
        'max-height': '0px',
        'opacity': 0,
        'textAlign': 'left',
        'overflow': 'hidden'
      }
    },

    '.MuiCardContent-root.css-46bh2p-MuiCardContent-root': {}
  }
});
const variants = [defaultCardVariant, imageAboveVariant, mediaCardVariant, imageLeftVariant];

const createCardVariants = (theme: Theme) => {
  return variants.map((creator) => creator(theme));
};

export default createCardVariants;
